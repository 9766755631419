

















































































import {Component, Ref, Vue} from "vue-property-decorator";
import Banner, {BannerEnum, BannerReference} from "@/models/Banner";
import StringTool from "@/services/tool/StringTool";
import FilePickerComponent from "@/components/FilePickerComponent.vue";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import BannerService from "@/services/BannerService";
import LangModule from "@/store/LangModule";

@Component({components: {FilePickerComponent}})
export default class BannerView extends Vue {
	@Ref() readonly form!: HTMLFormElement
	lang: any = getModule(LangModule).lang
	banner: Banner = new Banner()
	loading: boolean = false
	image: File | null = null
	urlRules = [
		(v: string) => v && v.length > 0 ? true : this.lang.urlRequired,
		(v: string) => StringTool.validateUrl(v) ? true : this.lang.urlNoValid
	]

	created() {
		this.refresh()
	}

	refresh() {
		BannerService.getBanner(this, Number(this.$route.params.bannerId))
	}

	typeToName(type: BannerEnum): string {
		return BannerService.typeToName(type)
	}

	referenceToName(reference: BannerReference): string {
		return BannerService.referenceToName(reference)
	}

	updateBanner() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.bannerUpdateNotification,
				 () => BannerService.patchBanner(this, Number(this.$route.params.bannerId), this.banner, this.image)
			))
		}
	}

	async activateBanner() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.bannerUpdateNotification,
			 () => BannerService.activateBanner(this, this.banner.id!!, this.banner.active!!)
		))
	}

	async clearBanner() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.clearBannerConfirmationMessage, async () => {
				 await BannerService.clearBanner(this, this.banner.id!!)
				 this.image = null
			 }
		))
	}

	async deleteBanner() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.deleteBannerConfirmationMessage, async () => {
				 await BannerService.deleteBanner(this, this.banner.id!!)
				 await this.$router.push("/banners")
			 }
		))
	}

}
